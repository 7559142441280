<script setup lang="ts">
/* import { TransitionRoot, TransitionChild, Dialog } from '@headlessui/vue' */

import { useModal } from '@/stores/modal'
const modalStore = useModal()

const props = defineProps({
    info: {
        type: String as any,
    },
})

const closeModal = () => {
    modalStore.setModal(null)
}
</script>

<template>
    <div class="fixed inset-0 overflow-y-auto z-[999999]" @click="closeModal">
        <div class="flex items-center justify-center min-h-full p-4 text-center relative">
            <slot></slot>
            <img
                :src="info"
                class="max-h-[90vh] max-w-[90vw]"
                alt="Obra"
                :onerror="`this.src=&quot;/no-image.jpg&quot;`"
            />
        </div>
    </div>
</template>
